import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ILabel } from '../../../common/interface/common.interface';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor() {
  }

  private clientIdSubject = new BehaviorSubject<string | null>(null);
  public labelsSubject = new BehaviorSubject<Record<string, ILabel>>({});
  public clientId$ = this.clientIdSubject.asObservable();
  public labels$ = this.labelsSubject.asObservable();

  setClientId(value: string): void {
    this.clientIdSubject.next(value);
  }

  getClientId(): string | null {
    return this.clientIdSubject.value;
  }

  setLabels(labels: Record<string, ILabel>): void {
    this.labelsSubject.next(labels);
  }
}
